import QrCode from "./components/QrCode";
import "./styles.css";

function App() {
  return (
      <div className="section container">
        <QrCode />
      </div>
  );
}

export default App;
